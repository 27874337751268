import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const ShareInfomationPageTemplate = ({ content, contentComponent }) => {
  return (
    <>
      <section
        className="l-section-lv2 l-main-content-hero -hero-lv2"
        id="access_section"
      >
        <div className="l-main-content-hero__inner">
          <div className="c-h2-title -block">
            <div className="c-h2-title__inner">
              <h2 className="c-h2-title__text">-INFOMATION-</h2>
              <p className="c-h2-title__description">
                当院のインフォメーション
              </p>
            </div>
          </div>

          <div className="c-info-doc">
            <div className="c-info-doc__inner">
              <div className="c-info-doc__row">
                <div className="c-info-doc__row__item">
                  <p className="c-info-doc__row__text">電話番号</p>
                </div>
                <div className="c-info-doc__row__item">
                  {/* <a
                    href="tel:08008051127"
                    className="c-info-doc__row__phone-number"
                  >
                    0800-805-1127
                  </a> */}
                  <a
                    href="tel:0927330871"
                    className="c-info-doc__row__phone-number"
                  >
                    092-733-0871
                  </a>
                </div>
              </div>

              <div className="c-info-doc__row">
                <div className="c-info-doc__row__item">
                  <p className="c-info-doc__row__text">当院住所</p>
                </div>
                <div className="c-info-doc__row__item">
                  <p className="c-info-doc__row__text">
                    &#12306;810-0001 福岡県福岡市中央区天神2丁目4−20
                    天神プラッサ 3F
                  </p>
                </div>
              </div>

              <div className="c-info-doc__row">
                <div className="c-info-doc__row__item">
                  <p className="c-info-doc__row__text">最寄り駅</p>
                </div>
                <div className="c-info-doc__row__item">
                  <p className="c-info-doc__row__text">
                    西鉄大牟田線　福岡(天神)駅
                  </p>
                  <p className="c-info-doc__row__text">
                    市営地下鉄七隈線　天神南駅
                  </p>
                </div>
              </div>

              <div className="c-info-doc__row">
                <div className="c-info-doc__row__item">
                  <p className="c-info-doc__row__text">診療時間</p>
                </div>
                <div className="c-info-doc__row__item">
                  <p className="c-info-doc__row__title">保険診療（下記以外）</p>
                  <p className="c-info-doc__row__text">
                    <div className="c-info-doc__row__table__item">平日</div>
                    <div className="c-info-doc__row__table__item">
                      9:30〜19:00　(休診12:30〜14:30)
                    </div>
                  </p>
                  <p className="c-info-doc__row__text">
                    <div className="c-info-doc__row__table__item">土日祝</div>
                    <div className="c-info-doc__row__table__item">
                      9:30〜17:30　(休診12:30〜14:30)
                    </div>
                  </p>
                  <p className="c-info-doc__row__description">
                    ※受付は診療終了時間の30分前までとなります。
                    <br />
                    ※予約制ではございませんので、そのままご来院ください。（受付順にご案内致します）
                  </p>
                  <p className="c-info-doc__row__title u-pc-mt30 u-sp-mt25">
                    美容診療、保険適応手術・レーザー治療
                  </p>
                  <p className="c-info-doc__row__text">
                    <div className="c-info-doc__row__table">
                      <div className="c-info-doc__row__table__item">平日</div>
                      <div className="c-info-doc__row__table__item">
                        9:30 〜 19:00　(休診12:30〜14:30)
                      </div>
                    </div>
                  </p>
                  <p className="c-info-doc__row__text">
                    <div className="c-info-doc__row__table__item">土日祝</div>
                    <div className="c-info-doc__row__table__item">
                      9:30 〜 17:30　(休診12:30〜14:30)
                    </div>
                  </p>
                  <p className="c-info-doc__row__description">
                    ※完全ご予約制となります。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="c-opening-table__container">
        <div className="c-opening-table">
          <div className="c-opening-table__inner">
            <div className="c-opening-table__head">
              <div className="c-opening-table__row">
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__title">診療時間</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__title">月</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__title">火</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__title">水</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__title">木</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__title">金</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__title">土</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__title">日</p>
                </div>
              </div>
            </div>
            <div className="c-opening-table__body">
              <div className="c-opening-table__row">
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">9:30&#65374;13:30</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
              </div>
              <div className="c-opening-table__row">
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">15:00&#65374;19:00</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
                <div className="c-opening-table__row__item">
                  <p className="c-opening-table__text">○</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

        <div className="c-info-full-map -small">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1633.296897600788!2d130.39727901867985!3d33.587319392358246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa4c21a5f3b239380!2z5aSp56We55qu44G156eR!5e0!3m2!1sja!2sjp!4v1657620173268!5m2!1sja!2sjp"></iframe>
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.609707862665!2d130.39570101555123!3d33.58948148073436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35419185a4893425%3A0xa4c21a5f3b239380!2z5aSp56We55qu44G156eR!5e0!3m2!1sja!2sjp!4v1574935623369!5m2!1sja!2sjp"></iframe> */}
        </div>
      </section>
    </>
  );
};

ShareInfomationPageTemplate.propTypes = {
  title: PropTypes.string,
};

const ShareInfomationPage = ({ data }) => {
  // const { markdownRemark: post } = data;

  return <ShareInfomationPageTemplate />;
};

ShareInfomationPage.propTypes = {
  data: PropTypes.object,
};

export default ShareInfomationPage;

export const shareInfomationPageQuery = graphql`
  query ShareInfomationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
