import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const SharePlacesPageTemplate = ({ content, contentComponent }) => {
  return (
    <>
      <section className="l-section-lv2 l-main-content-hero">
        <div className="l-main-content-hero__inner">
          <div className="c-box-title__outer">
            <div className="c-box-title">
              <h2 className="c-box-title__text">-PLACE-</h2>
              <p className="c-box-title__description">系列店舗一覧</p>
            </div>
          </div>

          <p className="c-copy-text">
            お客様が<span className="c-yellow-maker">通いやすい店舗</span>
            をご選択していただくことができます。
          </p>

          <section className="c-map-box">
            <div className="c-map-box__overlap-title">
              <div className="c-map-box__overlap-title__text">
                福岡天神地区店舗
              </div>
            </div>
            <div className="c-map-box__inner">
              <div className="c-map-box__item">
                <div className="c-map-box__title">
                  <h3 className="c-map-box__title__text">
                    <span className="c-yellow-maker">天神皮ふ科</span>
                  </h3>
                </div>
                <div className="c-map-box__phone">
                  <p className="c-map-box__sub-title">受付電話番号</p>
                  {/* <a
                    href="tel:08008051127"
                    className="c-map-box__phone__number"
                  >
                    0800-805-1127
                  </a> */}
                  <a href="tel:0927330871" className="c-map-box__phone__number">
                    092-733-0871
                  </a>
                </div>
                <div className="c-map-box__station">
                  <p className="c-map-box__sub-title">最寄り駅</p>
                  <div className="c-map-box__label-tile">
                    <div className="c-map-box__label-tile__item">
                      <div className="c-rounded-lable">
                        <p className="c-rounded-lable__text">天神駅</p>
                      </div>
                    </div>
                    <div className="c-map-box__label-tile__item">
                      <div className="c-rounded-lable">
                        <p className="c-rounded-lable__text">天神南駅</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-map-box__site">
                  <p className="c-map-box__sub-title">WEBサイト</p>
                  <a
                    href="http://www.tenjin-hifuka.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-map-box__site__link"
                  >
                    http://www.tenjin-hifuka.com/
                  </a>
                </div>
              </div>
              <div className="c-map-box__item">
                <div className="c-map-box__google-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1447.9164854401656!2d130.39603610722799!3d33.58747858873741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35419185a4893425%3A0xa4c21a5f3b239380!2z5aSp56We55qu44G156eR!5e0!3m2!1sja!2sjp!4v1657619722524!5m2!1sja!2sjp"
                    loading="lazy"
                  ></iframe>

                  {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.609707862665!2d130.39570101555123!3d33.58948148073436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35419185a4893425%3A0xa4c21a5f3b239380!2z5aSp56We55qu44G156eR!5e0!3m2!1sja!2sjp!4v1574935623369!5m2!1sja!2sjp"
                    loading="lazy"
                  ></iframe> */}
                </div>
              </div>
            </div>
          </section>

          <section className="c-map-box">
            <div className="c-map-box__overlap-title">
              <div className="c-map-box__overlap-title__text">
                福岡天神大名地区店舗
              </div>
            </div>
            <div className="c-map-box__inner">
              <div className="c-map-box__item">
                <div className="c-map-box__title">
                  <h3 className="c-map-box__title__text">
                    <span className="c-yellow-maker">
                      天神ホリスティックビューティークリニック
                    </span>
                  </h3>
                </div>
                <div className="c-map-box__phone">
                  <p className="c-map-box__sub-title">受付電話番号</p>
                  {/* <a
                    href="tel:08008051125"
                    className="c-map-box__phone__number"
                  >
                    0800-805-1125
                  </a> */}
                  <a href="tel:0120340817" className="c-map-box__phone__number">
                    0120-340-817
                  </a>
                </div>
                <div className="c-map-box__station">
                  <p className="c-map-box__sub-title">最寄り駅</p>
                  <div className="c-map-box__label-tile">
                    <div className="c-map-box__label-tile__item">
                      <div className="c-rounded-lable">
                        <p className="c-rounded-lable__text">天神駅</p>
                      </div>
                    </div>
                    <div className="c-map-box__label-tile__item">
                      <div className="c-rounded-lable">
                        <p className="c-rounded-lable__text">天神南駅</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-map-box__site">
                  <p className="c-map-box__sub-title">WEBサイト</p>
                  <a
                    href="http://tenjin-hbc.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-map-box__site__link"
                  >
                    http://tenjin-hbc.jp/
                  </a>
                </div>
              </div>
              <div className="c-map-box__item">
                <div className="c-map-box__google-map">
                  <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBdxXYlyZIm8ORnZSAgCtuuhVKnCeyvpaE&q=天神ホリスティックビューティークリニック&zoom=18"></iframe>
                </div>
              </div>
            </div>
          </section>

          <section className="c-map-box">
            <div className="c-map-box__overlap-title">
              <div className="c-map-box__overlap-title__text">
                福岡博多地区店舗
              </div>
            </div>
            <div className="c-map-box__inner">
              <div className="c-map-box__item">
                <div className="c-map-box__title">
                  <h3 className="c-map-box__title__text">
                    <span className="c-yellow-maker">博多皮ふ科</span>
                  </h3>
                </div>
                <div className="c-map-box__phone">
                  <p className="c-map-box__sub-title">受付電話番号</p>
                  {/* <a
                    href="tel:08008051128"
                    className="c-map-box__phone__number"
                  >
                    0800-805-1128
                  </a> */}
                  <a href="tel:0924145067" className="c-map-box__phone__number">
                    092-414-5067
                  </a>
                </div>
                <div className="c-map-box__station">
                  <p className="c-map-box__sub-title">最寄り駅</p>
                  <div className="c-map-box__label-tile">
                    <div className="c-map-box__label-tile__item">
                      <div className="c-rounded-lable">
                        <p className="c-rounded-lable__text">博多駅</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-map-box__site">
                  <p className="c-map-box__sub-title">WEBサイト</p>
                  <a
                    href="http://www.himawari-hakata.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-map-box__site__link"
                  >
                    http://www.himawari-hakata.com/
                  </a>
                </div>
              </div>
              <div className="c-map-box__item">
                <div className="c-map-box__google-map">
                  <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBdxXYlyZIm8ORnZSAgCtuuhVKnCeyvpaE&q=博多皮ふ科&zoom=18"></iframe>
                </div>
              </div>
            </div>
          </section>

          <section className="c-map-box">
            <div className="c-map-box__overlap-title">
              <div className="c-map-box__overlap-title__text">
                福岡六本松地区店舗
              </div>
            </div>
            <div className="c-map-box__inner">
              <div className="c-map-box__item">
                <div className="c-map-box__title">
                  <h3 className="c-map-box__title__text">
                    <span className="c-yellow-maker">六本松皮ふ科</span>
                  </h3>
                </div>
                <div className="c-map-box__phone">
                  <p className="c-map-box__sub-title">受付電話番号</p>
                  {/* <a
                    href="tel:08008052009"
                    className="c-map-box__phone__number"
                  >
                    0800-805-2009
                  </a> */}

                  <a href="tel:0924066203" className="c-map-box__phone__number">
                    092-406-6203
                  </a>
                </div>
                <div className="c-map-box__station">
                  <p className="c-map-box__sub-title">最寄り駅</p>
                  <div className="c-map-box__label-tile">
                    <div className="c-map-box__label-tile__item">
                      <div className="c-rounded-lable">
                        <p className="c-rounded-lable__text">六本松駅</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-map-box__site">
                  <p className="c-map-box__sub-title">WEBサイト</p>
                  <a
                    href="http://ropponmatsu-hifuka.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-map-box__site__link"
                  >
                    http://ropponmatsu-hifuka.jp/
                  </a>
                </div>
              </div>
              <div className="c-map-box__item">
                <div className="c-map-box__google-map">
                  <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBdxXYlyZIm8ORnZSAgCtuuhVKnCeyvpaE&q=六本松皮ふ科&zoom=18"></iframe>
                </div>
              </div>
            </div>
          </section>

          <section className="c-map-box">
            <div className="c-map-box__overlap-title">
              <div className="c-map-box__overlap-title__text">
                福岡久留米地区店舗
              </div>
            </div>
            <div className="c-map-box__inner">
              <div className="c-map-box__item">
                <div className="c-map-box__title">
                  <h3 className="c-map-box__title__text">
                    <span className="c-yellow-maker">ひまわりクリニック</span>
                  </h3>
                </div>
                <div className="c-map-box__phone">
                  <p className="c-map-box__sub-title">受付電話番号</p>
                  <a href="tel:0942467072" className="c-map-box__phone__number">
                    0942-46-7072
                  </a>
                </div>
                <div className="c-map-box__station">
                  <p className="c-map-box__sub-title">最寄り駅</p>
                  <div className="c-map-box__label-tile">
                    <div className="c-map-box__label-tile__item">
                      <div className="c-rounded-lable">
                        <p className="c-rounded-lable__text">西鉄久留米駅</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-map-box__site">
                  <p className="c-map-box__sub-title">WEBサイト</p>
                  <a
                    href="http://www.himawari-kurume.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="c-map-box__site__link"
                  >
                    http://www.himawari-kurume.jp/
                  </a>
                </div>
              </div>
              <div className="c-map-box__item">
                <div className="c-map-box__google-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3334.298990359457!2d130.52027169056004!3d33.31099803340358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3541a451a9e7312b%3A0xc141fd6fa7d6174d!2z44Gy44G-44KP44KK44Kv44Oq44OL44OD44Kv!5e0!3m2!1sja!2sus!4v1639117408961!5m2!1sja!2jp"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

SharePlacesPageTemplate.propTypes = {
  title: PropTypes.string,
};

const SharePlacesPage = ({ data }) => {
  // const { markdownRemark: post } = data;

  return <SharePlacesPageTemplate />;
};

SharePlacesPage.propTypes = {
  data: PropTypes.object,
};

export default SharePlacesPage;

export const sharePlacesPageQuery = graphql`
  query SharePlacesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
